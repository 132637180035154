import React, { useState, useEffect, useRef } from 'react';
import LoginForm from '../Forms/LoginForm'
import SignupForm from '../Forms/SignupForm'
import ModalController from './Controller'

const SignupModal = (props) => {

    const _closeModal = () => {
        childRef.current.closeModalFromController()
    }

    const childRef = useRef();


    return (
        <ModalController active={props.active} closeModal={props.onClick} style={props.style} ref={childRef}>
            {/* <div className='card-head w-100 d-flex justify-content-between pt-4 pl-4 pr-4 pb-4 bottom-bordered'>
                <div className='d-flex'>
                    <h4 className='mb-0'>Signup</h4>
                </div>
            </div> */}
            <div className="card-body pl-4 pr-4">
                <SignupForm closeModal={_closeModal} />
            </div>
        </ModalController> 
    );
}

export default SignupModal;
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { resetCartModal, updateModalStatus } from '../../store/actions/cartActions'
import LocationSideModal from '../Modals/LocationSideModal'
import DealsFinder from '../Modals/DealsFinder'
import NotificationSideModal from '../Modals/Notification';
import dynamic from 'next/dynamic'
import SidebarModal from '../Modals/SidebarModal';
import Menu from '../Modals/Menu';
import LoginModal from '../Modals/Login';
import SignupModal from '../Modals/Signup'
import AddToCartModal from '../Modals/AddToCartModal'
import ShoppingCartModal from '../Modals/ShoppingCartModal'
import DeliverySideModal from '../Modals/DeliverySideModal'
import useCart from '../../utils/useCart';
import ReviewModal from '../Modals/ReviewModal';
;
import isEmpty from '../../utils/isEmpty';
const AddRewardToCart = dynamic(() => import('../../components/Modals/AddRewardToCart'))

const ModalsController = ({embeeded}) => {

    const { modals, user, cartModal, fetched_cart } = useSelector(state => state)
    const dispatch = useDispatch()
    const [ mainScrollDisabled, setMainScrollDisabled ] = useState(false)

    const toggleModal = (modal) => {
        dispatch(updateModalStatus(modal))
    }

    useEffect(() => {
        mainScrollDisabled === false 
            ? document.body.style.overflow = 'unset' 
            : document.body.style.overflow = 'hidden';
    }, [mainScrollDisabled])

    useEffect(() => {
        let oneModalActive = false
        Object.values(modals).map( x => {
            if(x===true){
                oneModalActive = true
            }
        })
        setMainScrollDisabled(oneModalActive)
    }, [modals])

    useEffect(() => {
        if(!isEmpty(cartModal)){
            dispatch(updateModalStatus({cart:true}))
        }
    }, [cartModal])

    const [ get, add, remove ] = useCart()

    useEffect(() => {


        !isEmpty(user) && !fetched_cart && get()
    }, [])

    return (
        <>
        
            {/* {
                modals.deal && <DealsFinder 
                    active={modals.deal ? 'active' : ''} 
                    closeModal={()=>toggleModal({deal: false})}
                />
            } */}
            {
                modals.login && <LoginModal 
                    
                    active={modals.login ? 'active' : ''} 
                    onClick={()=>toggleModal({login:false})}
                />
            }
            {
                modals.review && (
                    !isEmpty(user) ? (
                        <ReviewModal 
                            active={modals.review ? 'active' : ''} 
                            onClick={()=>toggleModal({review:false})}
                            scopedReview={false}
                        />
                    ) : (
                        <LoginModal 

                            active={modals.review ? 'active' : ''} 
                            onClick={()=>toggleModal({review:false})}
                        />
                    )
                )
            }
            {
                modals.signup && <SignupModal 
                    active={modals.signup ? 'active' : ''} 
                    onClick={()=>toggleModal({signup:false})}
                />
            }
            {
                modals.cart && <AddToCartModal 
                    active={modals.cart ? 'active' : ''} 
                    onClick={()=>{
                        toggleModal({cart:false})
                        dispatch(resetCartModal())
                    }}
                />
            }
            {
                modals.menu && <Menu 
                    active={modals.menu ? 'active' : ''} 
                    onClick={()=>toggleModal({menu: false})}
                />
            }

            {
                modals.location && <LocationSideModal 
                    active={modals.location ? 'active' : ''} 
                    onClick={()=>toggleModal({location: false})}
                />
            }

            {
                modals.delivery && <DeliverySideModal 
                    active={modals.delivery ? 'active' : ''} 
                    onClick={()=>toggleModal({delivery: false})}
                />
            }
            {
                modals.shoppingCart && <ShoppingCartModal 
                    active={modals.shoppingCart ? 'active' : ''} 
                    onClick={()=>toggleModal({shoppingCart: false})}
                    embeeded={embeeded}
                />
            }
            {
                modals.addRewardToCart && <AddRewardToCart 
                    active={modals.addRewardToCart ? 'active' : ''} 
                    closeModal={()=>toggleModal({addRewardToCart: false})}
                />
            }
            {
                 !isEmpty(user) && <NotificationSideModal 
                    active={modals.notification ? 'active' : ''} 
                    onClick={()=>toggleModal({notification: false})}
                />
            }
            {
                modals.user && <SidebarModal 
                        active={modals.user ? 'active' : ''} 
                        onClick={()=>toggleModal({user: false})} 
                />
            }
        </>
    )

}

export default ModalsController
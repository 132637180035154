import React, { Component, useRef } from 'react';
import ModalController from './Controller';
import ReviewForm from './ReviewForm'

const ReviewModal = (props) =>  {

    const childRef = useRef();

    const _closeModal = () => {
        if(childRef.current) childRef.current.closeModalFromController()
        
    }

    return (
        <ModalController active={props.active} closeModal={props.closeModal || props.onClick} wide={true} ref={childRef}>
        
            <div className='card-head w-100 d-flex justify-content-center pt-4 pl-4 pr-4 pb-4 bottom-bordered'>
                
                <div className='d-flex'>
                    <h4 className='mb-0 pb-0 nunito-font pr-5'>
                        <span style={{fontWeight:'700'}}>Leave a review for </span>
                        <span style={{fontWeight:'900'}}>{props.for}</span>
                    </h4>
                </div>
                {/* <div className='close-modal'>
                    <i className='bx bx-x cursor-pointer' onClick={props.close}></i>
                </div> */}
            </div>
            <div className='card-body'>
                <ReviewForm 
                    document={props.document} 
                    closeModal={_closeModal} 
                    reload={props.reload} 
                    scopedReview={props.scopedReview}
                    model={props.model}
                />
            </div>
        </ModalController>
    );
}

export default ReviewModal;
import React, { useState, useEffect } from 'react'
import {successAdded, errorAdding } from '../../helpers/toasts'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { reviewService } from '../../services'
import * as Yup from 'yup';
import StarRatings from 'react-star-ratings';

const ReviewForm = ({closeModal, document, reload, scopedReview, model}) => {

    const [ rating, setRating ] = useState(1)
    const initialValues = {
        title: scopedReview ? scopedReview.title : '', 
        body: scopedReview ? scopedReview.body : ''
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .max(60, 'Max length of title is 60'),
        body: Yup.string()
            .max(5000, 'Body is too long')
            .required(),
    });

    useEffect(() => {
        setRating(scopedReview.rating || 1)
    }, [scopedReview])

    function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
      
        fields.rating = rating;
        setStatus();
        if(!scopedReview){
            console.log(model);
            fields[model.toLowerCase()] = document
            createReview(fields, { setStatus, setSubmitting, resetForm })
        } else {
            alert('erterterterterter')
            editReview(fields, { setStatus, setSubmitting, resetForm }, scopedReview._id)
        }     
    }

    const createReview = (fields, { setStatus, setSubmitting, resetForm }) => {
    
        reviewService.create(fields).then(() =>{
            successAdded('Review sent!')
            reload();
        }).catch((er) => {
           
            errorAdding(er.message)
            
        }).finally(()=>{
            resetForm()
            closeModal();
            setRating(1)
            // setSubmitting(false);
        })
    }

    const editReview = (fields, { setStatus, setSubmitting, resetForm }, reviewId) => {
        reviewService.update(scopedReview._id, fields).then(() =>{
            successAdded('Review edited!')
            reload();
        }).catch((er) => {
            
            errorAdding(er.message)
            
        }).finally(()=>{
            resetForm()
            closeModal();
            setRating(1)
            // setSubmitting(false);
        })
    }

    return(
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ errors, touched, isSubmitting, handleReset, handleChange, setFieldTouched, setFieldValue, resetForm }) => (
            <Form>
                <div className="form-group">
                    <label htmlFor='title'>Title</label>
                    <Field name="title" type="text" placeholder="Input a title for your review" className={'form-controla' + (errors.title && touched.title ? ' is-invalid' : '')} />
                    <ErrorMessage name="title" component="div" className="invalid-feedback" />
                </div>
                <div className="form-group">
                    <label htmlFor='body'>Body</label>
                    <Field name="body" as='textarea' placeholder="Share your experience with the community" className={'form-controla-area' + (errors.body && touched.body ? ' is-invalid' : '')} />
                    <ErrorMessage name="body" component="div" className="invalid-feedback" />
                </div>
                <div className="form-group rating-form-group">
                    <label htmlFor='body'>Set your rating</label>
                    <StarRatings
                        starRatedColor="rgb(255, 215, 0)"
                        starEmptyColor='#dadada'
                        starDimension="50px"
                        starSpacing="0px"
                        numberOfStars={5}
                        name='rating'
                        rating={rating}
                        changeRating={newValue => {
                            setRating(newValue)
                        }}
                    />
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                    <button type="submit" disabled={isSubmitting} className="default-btn">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Submit review
                    </button>&nbsp;
                    <button type='button' className='optional-btn' onClick={()=>{closeModal();resetForm()}}>Cancel</button>
                </div>
            </Form>
        )}
        </Formik>
    )
}

export default ReviewForm;
import React, { Component, useState, useEffect } from 'react';
import { MY_LOCATION, MY_LOCATION_PLACE_NAME,MY_LOCATION_PLACE, BACKGROUND_STATE } from '../../helpers/constTypes'
import { mapboxService, stateService } from '../../services'
import cookie from 'js-cookie';
import _, { debounce } from 'lodash'
import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl';
import SVG from "react-inlinesvg";
import { useRouter } from 'next/router'
import { reloadLocation } from '../../utils/locationDetector';
import { getBusinessSubzone, getCityId } from '../../helpers/location';

const hookClass = (props) => {

    const [ active, setActive ] = useState(false)

    const shouldCloseModal = async  (e) => {
        if(typeof e.target.className ==='string'){
            (e.target.className.includes('shoppingCartModal')) && closeThis()
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setActive(true)
        }, 10);
    }, [props.active])

    const closeThis = () => {
        setActive(false);
        setTimeout(() => {
            props.onClick()
        }, 500)
    }

    const router = useRouter();

    return (
        <div onMouseDown={(e)=>shouldCloseModal(e)} className={`shoppingCartModal right ${active ? 'active' : ''}`}> 
            <div className="modal-innter-content">
                <LocationSideModal {...props} router={router} onClick={closeThis} />
            </div>
        </div>
    )
    
}


class LocationSideModal extends Component {
    state = {
        modal: false,
        error: {},
        detectingLocation: false,
        location: false,
        inputValue: '',
        message:'',
        isActionLoading: false,
        search:'',
        features:[],
        [MY_LOCATION_PLACE_NAME]: '',
    };

    closeModal = () => {
        this.props.onClick(this.state.modal);
    }

    componentDidMount (){
        // let c = document.getElementsByClassName('react-geocoder')[0].children;
        // c[0].placeholder=''
        const my_location_obj = (cookie.get(MY_LOCATION) ? JSON.parse(cookie.get(MY_LOCATION)) : false)
        this.setState({location:my_location_obj})
        document.addEventListener("keydown", this.escFunction, false);
    }

    escFunction = (e) => {
        if(e.keyCode === 27){
            this.closeModal()
        }
      }


    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
      }


     

    

    handleSearchInput = (e) => {
        let { name, value } = e.target 
        this.setState({[name]:e.target.value})
        this.debouncedFetch(encodeURIComponent(value))
    }

    debouncedFetch = debounce(async (value) => {
        this.geocoding(value)
    }, 1000);

    geocoding = async (value) => {
        try{
            if(value.length>0){
                let response = await mapboxService.geocoding(value)
                this.setState({features: response.features})
                if(!response.features.length > 0){
                    this.setState({message: 'No places found'})
                }
            } else {
                this.setState({features: []})
            }
        } catch(err){
            console.log(err.message)
        }
    }

    reverseGeoFetch = (position) => {
      
        this.handleActionsLoadingState(true)
        
        mapboxService.reverseGeocoding(position.coords.longitude, position.coords.latitude, 'types='+encodeURIComponent(['address', 'place', 'region', 'postcode']))
            .then(async res => {

                // console.log(res)

                // let cityInfo = await getCityId(res.features.find( x => x.id.includes('place')))

                let stateInfo = await this.getStateId(res.features.find( x => x.id.includes('region')).id) 
                await cookie.set(BACKGROUND_STATE, stateInfo.background, { path: '/' })

                let myLocationPlace = {
                    place: res.features.find( x => x.id.includes('place')).text,
                    region: res.features.find( x => x.id.includes('region')).text,
                    center: res.features.find( x => x.id.includes('place')).center,
                    stateId: stateInfo._id,
                    // cityId: cityInfo._id,
                    state:{
                        _id: stateInfo._id,
                        isDeliveryAvailable: stateInfo.isDeliveryAvailable,
                        isCannabisLegal: stateInfo.isCannabisLegal
                    }
                }

                let state = res.features.find( x => x.id.includes('region')).text
                let zip = res.features.find( x => x.id.includes('postcode'))
                let city = res.features.find( x => x.id.includes('place'))
                let secondText = res.features.find( x => x.id.includes('region')).context[0].short_code
                if(zip){
                    secondText = zip.text.toUpperCase()
                }
                if(city){
                    state = city.text
                }

                let locationPlaceName = state + ', ' + secondText

                
                await getBusinessSubzone(position.coords.longitude, position.coords.latitude, cookie)


                await cookie.set(MY_LOCATION_PLACE_NAME, locationPlaceName, { path: '/' });
                if(res.features.find( x => x.id.includes('region'))){
                    await cookie.set('REGION', res.features.find( x => x.id.includes('region')).text, { path: '/' });
                }
                await cookie.set('MY_LOCATION_PLACE_FULLNAME', res.features[0].place_name, { path: '/' });
                await cookie.set(MY_LOCATION, JSON.stringify({
                    longitude: position.coords.longitude, 
                    latitude: position.coords.latitude,
                    zoom:8,
                    boundingRadius:80467,
                    type: 'AUTO'
                }), { path: '/' })
                await cookie.set(MY_LOCATION_PLACE, JSON.stringify(myLocationPlace), { path: '/' });
                if(myLocationPlace.stateId){
                    
                    let state = await stateService.findById(myLocationPlace.stateId)
                    await cookie.set(BACKGROUND_STATE, state.background, { path: '/' })
                } 
                this.setState({[MY_LOCATION_PLACE_NAME]: locationPlaceName.place_name})
                this.handleActionsLoadingState(false)
                this.closeModal()
                reloadLocation(window.location.pathname, this.props.router, cookie)
            })  
    }


    handleActionsLoadingState(val) {
        this.setState({isActionLoading:val})
    }



    getStateId = async (uuid) => {
        try {
            let state = await stateService.findByAbr(uuid)
            return state
        } catch {
            return false
        }
        
    }
    onSelected = async (item) => {
       
        let place = await mapboxService.reverseGeocoding(item.center[0], item.center[1], 'types='+encodeURIComponent(['address', 'place', 'region', 'postcode']))
    
        // let cityInfo = await getCityId(place.features.find( x => x.id.includes('place')))
       console.log(place)
        let stateInfo = await this.getStateId(place.features.find( x => x.id.includes('region')).properties.short_code.replace('US-', '')) 
        await cookie.set(BACKGROUND_STATE, stateInfo.background, { path: '/' })
        let myLocationPlace = {
            place: place.features.find( x => x.id.includes('place')).text,
            region: place.features.find( x => x.id.includes('region')).text,
            center: place.features.find( x => x.id.includes('place')).center,
            stateId: stateInfo._id,
            // cityId: cityInfo._id,
            state:{
                _id: stateInfo._id,
                isDeliveryAvailable: stateInfo.isDeliveryAvailable,
                isCannabisLegal: stateInfo.isCannabisLegal
            }
        }


        let state = place.features.find( x => x.id.includes('region')).text
        let zip = place.features.find( x => x.id.includes('postcode'))
        let city = place.features.find( x => x.id.includes('place'))
        let secondText = place.features.find( x => x.id.includes('region')).context[0].short_code
        if(zip){
            secondText = zip.text.toUpperCase()
        }
        if(city){
            state = city.text
        }
        let locationPlaceName = state + ', ' + secondText



        const viewportInfo = {
            longitude: item.center[0], 
            latitude: item.center[1],
            zoom:8,
            boundingRadius:80467,
            type: 'MANUAL'
        }
      
        await getBusinessSubzone(item.center[0], item.center[1], cookie)

        await cookie.set(MY_LOCATION_PLACE_NAME, locationPlaceName, { path: '/' });
        if(place.features.find( x => x.id.includes('region'))) {
            await cookie.set('REGION', place.features.find( x => x.id.includes('region')).text, { path: '/' });
        }
        await cookie.set('MY_LOCATION_PLACE_FULLNAME', place.features[0].place_name, { path: '/' });
        await cookie.set(MY_LOCATION_PLACE, JSON.stringify(myLocationPlace), { path: '/' });
        await cookie.set(MY_LOCATION, JSON.stringify(viewportInfo), { path: '/' })
        this.closeModal()
        reloadLocation(window.location.pathname, this.props.router, cookie)
    }

    removeLocation = async () => {
        this.setState({location:false})
        await cookie.remove(MY_LOCATION_PLACE_NAME)
        await cookie.remove(MY_LOCATION_PLACE);
        await cookie.remove(MY_LOCATION)
        this.closeModal()
        reloadLocation(window.location.pathname, this.props.router, cookie, window)
    }

    detectLocation = async () => {
        this.setState({detectingLocation:true})
        if ( navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({name:'geolocation'}).then((result) => {
                if (result.state === 'granted') {
                    navigator.geolocation.getCurrentPosition(this.reverseGeoFetch, this.showError, options);
                } else if (result.state == 'prompt') {
                    navigator.geolocation.getCurrentPosition(this.reverseGeoFetch, this.showError, options);
                } else {
                    alert('Please allow geolocation services')
                }
            })
        } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.reverseGeoFetch, this.showError, options);
        } else { 
            alert('Geolocation is not supported by this browser')
        }
    }

    showError(error) {
        switch(error.code) {
            case error.PERMISSION_DENIED:
                // alert("User denied the request for Geolocation.")
                break;
            case error.POSITION_UNAVAILABLE:
                alert("Location information is unavailable.")
                break;
            case error.TIMEOUT:
                alert("The request to get user location timed out.")
                break;
            case error.UNKNOWN_ERROR:
                alert("An unknown error occurred.")
                break;
        }
    }
   

    options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };

    


    render() {
        const {viewport} = this.state
        return (
            <React.Fragment>
                
                        <button type="button" className="close" onClick={this.closeModal}>
                            <span aria-hidden="true">
                                <i className='bx bx-x'></i>
                            </span>
                        </button>
                     
                        <div className="modal-body">
                            <h3>Location</h3>
                            <div>
                                <ul className="sidebar-contact-info cur-location">
                                    <li>
                                        {
                                            !cookie.get('MY_LOCATION_PLACE_FULLNAME') ? 'Please set your location' : <><i className="bx bx-map"></i>{cookie.get('MY_LOCATION_PLACE_FULLNAME').replace('"', '').replace('"', '')}</>
                                        }
                                    </li>
                                    {/* {
                                        cookie.get(MY_LOCATION_PLACE_NAME) && <li>
                                            <br></br>
                                            <button className='btn text' onClick={this.removeLocation}>Remove location</button>
                                        </li>
                                    } */}
                                    
                                </ul>
                                <br/>
                                <h3>{!cookie.get(MY_LOCATION_PLACE_NAME) ? 'Enter your location' : 'Update your location' }</h3>
                                <center><p className='mb-3'>Find products close to you easily</p></center>
                                <div className='centered columned'>
                                    <button type='button' disabled={this.state.detectingLocation} className='default-btn rounded' onClick={()=>navigator.geolocation.getCurrentPosition(this.reverseGeoFetch, this.showError, this.options)}>
                                        {
                                            this.state.detectingLocation ? <span className="spinner-border spinner-border-sm mr-1"></span>  : 'DETECT MY LOCATION'
                                        }
                                        
                                    </button>
                                    <br/>
                                    <p>or</p>
                                    <div className='search-input-wrapper w-100'>
                                        <input 
                                            type='search' 
                                            name='search'
                                            placeholder='Enter location' 
                                            className='form-controla rounded search-input'
                                            value={this.state.search}
                                            onChange={(e) => this.handleSearchInput(e)}
                                        />
                                        <i className="bx bx-search"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.features.length > 0 ? (
                                <ul className='geocoding-results'>
                                {
                                     this.state.features.map((x, i) =>
                                        <li 
                                            key={i} 
                                            onClick={()=>this.onSelected(x)}
                                        >
                                            <i className='bx bx-map'></i>
                                            {x.place_name}
                                        </li>
                                    )
                                }
                                </ul>
                            ) : (
                                <>
                                {
                                    this.state.message.length > 0 && <div className='pl-3 pr-3'>  
                                        <SVG
                                            src={toAbsoluteUrl("/static/icons/Error/search-error.svg")}
                                        >

                                        </SVG>
                                        <h4 className='location-messages'>{this.state.message}</h4>
                                        <p><center>Adjust the search text and try again</center></p>
                                    </div>
                                }
                                </>
                            )
                        }
                        
                        
                
            </React.Fragment>
        );
    }
}

export default hookClass

import React, { useState, useEffect } from 'react';
import { categoryService, mapboxService } from '../../services'
import useDebounce from '../../helpers/useDebounce'
import CircleSpinner from '../LoadingAnimation/CircleSpinner'
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl';
import { useRouter } from 'next/router'
import queryString from 'query-string'
import moment from 'moment';
import ModalController from './Controller'
import cookie from 'js-cookie'
import { MY_LOCATION } from '../../helpers/constTypes';

const DealsFinder = (props) => {


    return (
        <ModalController active={props.active} closeModal={props.closeModal} wide={true}>
            <MainDealFinder {...props} />
            
        </ModalController>
    );
    
}

export const MainDealFinder = (props) => {
    const router = useRouter()

    const [ category, setCategory ] = useState(null)
    const [ feeling, setFeeling ] = useState('')
    const [ bbox, setBbox ] = useState('')
    const [ day, setDay ] = useState('')

    const fetchDeals = async () => {

        let filter = {
            filterfield: [],
            filtervalue: [],
            filtertype: [],
        }
       
        if(Array.isArray(bbox) && bbox.length === 4){
            filter.bbox = bbox
        } else {
            filter = { ...filter, ...bbox, sortField:'distance', sortOrder:'asc' }
        }

       
        
        if(category && category!='Skip'){
            category.map(x => {

                if(x.type === 'child' && x!='Skip'){
                    filter.filterfield.push('categories')
                    filter.filtervalue.push(x.slug)
                    filter.filtertype.push('eq')
                }
                if(x.type === 'parent' && x!='Skip'){
                    filter.filterfield.push('productTypes')
                    filter.filtervalue.push(x.value)
                    filter.filtertype.push('eq')
                }
            })
        }
        
        if(feeling && feeling!='Skip' && feeling.length>0){
            feeling.map(x => {
                filter.filterfield.push('target_audience')
                filter.filtervalue.push(x.value)
                filter.filtertype.push('eq')
            })
        }
        if(filter.filterfield.length===0){
            delete  filter.filterfield
            delete  filter.filtervalue
            delete  filter.filtertype
        }
        router.push('/deals/find/'+day+'?'+queryString.stringify(filter, {arrayFormat:'bracket'}), '/deals/find/'+day+'?'+queryString.stringify(filter, {arrayFormat:'bracket'}))
        if(!props.type){
            props.closeModal()
        }
    }

    useEffect(() => {
        if(category && day && bbox && day){
            fetchDeals()
        }
    }, [bbox])
 
    const goBack = () => {
        if(day){
            setDay('')
        }  else if(feeling){
            setFeeling('')
        } else {
            setCategory('')
        }
    }
    return (
        <>
            
              
                    <div className='card-head w-100 d-flex justify-content-center pt-4 pl-4 pr-4 pb-4 bottom-bordered'>
                {
                    category && <span className='back-button cursor-pointer' onClick={goBack}>
                        <SVG src={toAbsoluteUrl("/static/icons/Navigation/Arrow-left.svg")}/>
                    </span>
                }
                <div className='d-flex'>
                    <h4 className='mb-0 pb-0 nunito-font'>Weedzly Deal Finder</h4>
                </div>
                {/* <div className='close-modal'>
                    <i className='bx bx-x cursor-pointer' onClick={props.close}></i>
                </div> */}
            </div>
                
            
           
            {
                !category && <CategorySelector setCategory={setCategory} />
            }
            {
                (category && !feeling) && <FeelingSelector setFeeling={setFeeling} />
            } 
            {
                (category && feeling && !day) && <DaySelector setDay={setDay} />
            } 
            {
                (category && feeling && day) && <PlaceSelector setBbox={setBbox} />
            }
            {/* <div className='card-footer p-3 d-flex justify-content-end'>
                <button className='btn btn-mat-purple'>Next</button>
            </div>            */}
            {/* <p className='advice'>When you click confirm you are accepting our terms of use and our cookies policies, used for improve the experience</p> */}
            {
                <div className='card-footer d-flex justify-content-between' >
                    <div>
                        {
                            !category && !day && <small className='text-bold text-muted'>Find</small>
                        }
                        { 
                            category && <small className='text-bold text-muted'>Find</small>
                        }
                        {
                            !category ? '' : !category.length > 0 ? (
                                <span>
                                    <SVG 
                                        src={toAbsoluteUrl("/static/icons/Navigation/Angle-right.svg")}
                                    />
                                    <small className='text-bold text-muted'>Skip</small>
                                </span>
                            ) : (
                                <span>
                                    <SVG 
                                        src={toAbsoluteUrl("/static/icons/Navigation/Angle-right.svg")}
                                    />
                                    <small className='text-bold text-muted'>{category.length} Categor{category.length > 0 ? 'ies' : 'y'}</small>
                                        
                                    
                                </span>
                            )
                            
                        }
                        {
                            feeling && <span>
                                <SVG 
                                    src={toAbsoluteUrl("/static/icons/Navigation/Angle-right.svg")}
                                />
                                <small className='text-bold text-muted capitalize'>{feeling != 'Skip' ? feeling.length+' target audience' : 'Skip'}{feeling != 'Skip' && feeling.length > 1 ? 's' : feeling.length === 1 ? '' : ''}</small>
                            </span>
                        }
                        {/* {
                            audience && <span>
                                <SVG 
                                    src={toAbsoluteUrl("/static/icons/Navigation/Angle-right.svg")}
                                />
                                <small className='text-bold text-muted'>{audience.label}</small>
                            </span>
                        } */}
                        {
                            day && <span>
                                <SVG 
                                    src={toAbsoluteUrl("/static/icons/Navigation/Angle-right.svg")}
                                />
                                <small className='text-bold text-muted'>{day}</small>
                            </span>
                        }
                    </div>
                    <div>
                        {
                            (category && !feeling) ? <button className='btn btn-text btn-sm btn-link m-0 p-0' onClick={
                                ()=>{
                                    !category ? setCategory('Skip') : !feeling ? setFeeling('Skip') : !day ? setDay('Skip') : ''
                                }
                            }>
                                Skip
                            </button> : ''
                        }
                        
                    </div>
                </div>
                
            }    
            </>
    )
}

export default DealsFinder;

const CategorySelector = (props) => {

    const [ categoriesList, setCategoriesList ] = useState([])
    const [ search, setSearch ] = useState('')
    const [ loading, setLoading ] = useState(false)

    let [ selectedItems, setSelectedItems ] = useState([])

    const debouncedSearchTerm = useDebounce(search, 500);

    useEffect(() => {
        fetchSearch()
    }, [debouncedSearchTerm]);

    const fetchSearch = async () => {
        try {
            if(search != ''){
                setLoading(true)
                const response = await categoryService.getPaginated('?page=0&size=10&search='+search)
                setCategoriesList(response.totalData)
                setLoading(false)
            } else {
                setCategoriesList([])
            }
        } catch(err) {

            console.log(err)

        }
    }

    // const fetchInitialCategories = async () => {
    //     try {
    //         setLoading(true)
    //         let categories = await categoryService.getPaginated('?size=8&page=0')
    //         setCategoriesList(categories.totalData)
    //         setLoading(false)
    //     } catch(err){
    //         console.log(err)
    //     }
    // }

    let initialCategories = [
        {
            label: 'CBD',
            value: 'CBD',
            type: 'parent',
            icon: toAbsoluteUrl("/static/icons/cbd-colored.svg")
        },
        {
            label: 'Pre-rolls',
            value: 'PRE-ROLL',
            type: 'parent',
            icon: toAbsoluteUrl("/static/icons/preroll-colored.svg")
            
        },
        {
            label: 'Concentrates',
            value: 'CONCENTRATES',
            type: 'parent',
            icon: toAbsoluteUrl("/static/icons/concentrates-colored.svg")
            
        },
        {
            label: 'Edibles',
            value: 'EDIBLES',
            type: 'parent',
            icon: toAbsoluteUrl("/static/icons/edibles-colored.svg")
        },
        {
            label: 'Flower',
            value: 'FLOWER',
            type: 'parent',
            icon: toAbsoluteUrl("/static/icons/flower-colored.svg")
        },
        {
            label: 'Vapes',
            value: 'VAPES',
            type: 'parent',
            icon: toAbsoluteUrl("/static/icons/weed-vape.svg")
        },
        {
            label: 'Topicals',
            value: 'TOPICALS',
            type: 'parent',
            icon: toAbsoluteUrl("/static/icons/topicals-colored.svg")
        },
        {
            label: 'Pre Roll',
            slug: 'pre-roll',
            type: 'child',
            icon: toAbsoluteUrl("/static/icons/preroll-colored.svg")
        },
        {
            label: 'Gear',
            value: 'GEAR',
            type: 'child',
            icon: toAbsoluteUrl("/static/icons/gear-colored.svg")
        },
    ]

    // useEffect(() => {
    //     if(categoriesList.length === 0){
    //         fetchInitialCategories()
    //     }
    // }, [categoriesList])


    return(
        <>
            <div className='card-body pl-4 pr-4'>
                <p className='normal-size'>What type of product are you looking for?</p>
                {/* <div className="big-search d-flex mb-5 mt-3">
                    
                    <input type="search" name="search" onChange={(e)=>setSearch(e.target.value)} placeholder="Search a category" className="form-controla rounded search-input" value={search} />
                    <button class="btn btn-purple">Search</button>
                    <i className='bx bx-search'></i>
                </div> */}
                <span>
                    Selected: {
                        selectedItems.map(x => {
                            return <span className='badge badge-primary ml-1 mr-1'>{x.label || x.name}</span>
                        })
                    }
                </span>
            </div>
            {
                loading ? <CircleSpinner fullScreen={false} radius={50} /> :(
                    <div className='d-flex justify-content-center flex-column mb-4'>
                        {
                            (debouncedSearchTerm && categoriesList.length===0) && <div className='align-items-center flex-column justify-content-center d-flex'>
                                <SVG 
                                    src={toAbsoluteUrl("/static/icons/search-error.svg")}
                                    style={{width:'120px', maxWidth: '80%'}}
                                />
                                <small className='text-bold'>No results found</small>
                            </div>
                        }
                    
                        {
                            categoriesList && categoriesList.length > 0 ? (
                                <ul className='deals-result-grid-2'>
                                    {
                                        categoriesList && categoriesList.map( x => <li 
                                            className={`pl-4 ${selectedItems.find(y => y._id===x._id) ? 'active' : ''}`}
                                            onClick={()=>{
                                                selectedItems.find(y => y._id === x._id) ? setSelectedItems(selectedItems.filter(y => y._id != x._id)) : setSelectedItems(selectedItems.concat({...x, type:'child'}))
                                            }}
                                        >
                                            <small>{x.name}</small>
                                        </li>)
                                    }
                                </ul>
                            ) : (
                                <ul className='deals-result-grid-2'>
                                    {
                                        initialCategories && initialCategories.map( x => <li 
                                            className={`pl-4 ${selectedItems.find(y => y.value===x.value) ? 'active' : ''}`}
                                            onClick={()=>{
                                                selectedItems.find(y => y.value === x.value) ? setSelectedItems(selectedItems.filter(y => y.value != x.value)) : setSelectedItems(selectedItems.concat(x))
                                            }}
                                        >
                                            <SVG 
                                                src={x.icon}
                                                width={'24px'}
                                                height={'24px'}
                                                style={{fill:'#608ac7'}}
                                            />
                                            {/* <iimg src={x.icon} width='48'></iimg>  */}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <small>{x.label}</small>
                                        </li>)
                                    }
                                </ul>
                            )
                        }
                    </div>
                )
            }
            <div className='d-flex justify-content-end mb-3 mr-4' >
                <button className='btn btn-primary' onClick={()=>props.setCategory(selectedItems)}>Next</button>
            </div>
        </>
    )
}


const deal_target = [
    // {
    //     label:'Temporal',
    //     field:'type',
    //     value:'DEFAULT'
    // },
    // {
    //     label:'Daily',
    //     field:'type',
    //     value:'DAILY'
    // },
    {
        icon: toAbsoluteUrl("/static/icons/people.svg"),
        label:'Everyone',
        field:'target_audience',
        value:'EVERYONE'
    },
    {
        icon: toAbsoluteUrl("/static/icons/veteran_1.svg"),
        label:'Veteran',
        field:'target_audience',
        value:'VETERAN'
    },
    {
        icon: toAbsoluteUrl("/static/icons/disabled.svg"),
        label:'Disability/SSI',
        field:'target_audience',
        value:'DISABILITY'
    },
 
    {
        icon: toAbsoluteUrl("/static/icons/3rd-award.svg"),
        label:'Second Purchase',
        field:'target_audience',
        value:'THIRD_PURCHASE'
    },
    {
        icon: toAbsoluteUrl("/static/icons/resident.svg"),
        label:'Local resident',
        field:'target_audience',
        value:'LOCAL_RESIDENT'
    },
    {
        icon: toAbsoluteUrl("/static/icons/worker.svg"),
        label:'Essential worker',
        field:'target_audience',
        value:'ESSENTIAL_WORKER'
    },
    {
        icon: toAbsoluteUrl("/static/icons/police.svg"),
        label:'Law enforcement',
        field:'target_audience',
        value:'LAW_ENFORCEMENT'
    },
    {
        icon: toAbsoluteUrl("/static/icons/medical-marijuana-card.svg"),
        label:'First-Time Patient',
        field:'target_audience',
        value:'FTP'
    },
    {
        icon: toAbsoluteUrl("/static/icons/senior-citizen_1.svg"),
        label:'Senior Citizen',
        field:'target_audience',
        value:'CITIZEN'
    },
    {
        icon: toAbsoluteUrl("/static/icons/medical-report.svg"),
        label:'Medical Patient',
        field:'target_audience',
        value:'MEDICAL'
    },
    {
        icon: toAbsoluteUrl("/static/icons/birthday_1.svg"),
        label:'Birthday',
        field:'target_audience',
        value:'BIRTHDAY'
    },
    {
        icon: toAbsoluteUrl("/static/icons/military.svg"),
        label:'Active Military',
        field:'target_audience',
        value:'MILITARY'
    },
    {
        icon: toAbsoluteUrl("/static/icons/cashier.svg"),
        label:'Industry Worker',
        field:'target_audience',
        value:'INDUSTRY'
    },
    {
        icon: toAbsoluteUrl("/static/icons/student.svg"),
        label:'Student',
        field:'target_audience',
        value:'STUDENT'
    },
    {
        icon: toAbsoluteUrl("/static/icons/ambulance.svg"),
        label:'First Responder',
        field:'target_audience',
        value:'FIRST_RESPONDER'
    },
    {
        icon: toAbsoluteUrl("/static/icons/snap_1.svg"),
        label:'SNAP',
        field:'target_audience',
        value:'SNAP'
    },
    {
        icon: toAbsoluteUrl("/static/icons/renewal_1.svg"),
        label:'Card Renewal',
        field:'target_audience',
        value:'CARD_RENEWAL'
    },
    {
        icon: toAbsoluteUrl("/static/icons/pediatrics_1.svg"),
        label:'Pediatrics',
        field:'target_audience',
        value:'PEDIATRICS'
    },
    {
        icon: toAbsoluteUrl("/static/icons/referral_1.svg"),
        label:'Referral',
        field:'target_audience',
        value:'REFERRAL'
    },
]



const FeelingSelector = ({setFeeling}) => {

    let [ selectedItems, setSelectedItems ] = useState([deal_target.find(x=>x.value==='EVERYONE')])

    return(
        <>
            <div className='card-body pl-4 pr-4'>
                <p className='normal-size'><strong>Deals For</strong></p>
            </div>
           
            <div className='d-flex justify-content-center flex-column mb-4'>
                <ul className='deals-result-grid-2'>
                                    {
                                        deal_target && deal_target.map( x => <li 
                                            className={`pl-4 ${selectedItems.find(y => y.value===x.value) ? 'active' : ''}`}
                                            onClick={()=>{
                                                selectedItems.find(y => y.value === x.value) ? setSelectedItems(selectedItems.filter(y => y.value != x.value)) : setSelectedItems(selectedItems.concat(x))
                                            }}
                                        >
                                            <SVG 
                                                src={x.icon}
                                                width={'24px'}
                                                height={'24px'}
                                                style={{fill:'#608ac7'}}
                                            />
                                            {/* <iimg src={x.icon} width='48'></iimg>  */}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <small>{x.label}</small>
                                        </li>)
                                    }
                                </ul>
            </div>

            <div className='d-flex justify-content-end mb-3 mr-4' >
                <button className='btn btn-primary' onClick={()=>setFeeling(selectedItems)}>Next</button>
            </div>
            
        </>
    )
}





const PlaceSelector = (props) => {

    const [ categoriesList, setCategoriesList ] = useState([])
    const [ search, setSearch ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ locationError, setLocationError ] = useState(false)

    const debouncedSearchTerm = useDebounce(search, 500);

    useEffect(() => {
        fetchSearch()
    }, [debouncedSearchTerm]);

    const fetchSearch = async () => {
        try {
            if(search != ''){
                setLoading(true)
                const response = await mapboxService.geocoding(search, ['place'])
                setCategoriesList(response.features)
                setLoading(false)
            } else {
                setCategoriesList([])
            }
        } catch(err) {

            console.log(err)

        }
    }

    return (
        <>
            <div className='card-body pl-4 pr-4'>
                <p className='normal-size'><strong>Finally, search and set the area where you want to find deals</strong></p>
                <div className="big-search d-flex mb-2 mt-3">
                    <input type="search" autocomplete="off" name="search" placeholder='Search a city' onChange={(e)=>setSearch(e.target.value)} className="form-controla rounded search-input" value={search} />
                    <button className="btn btn-purple">Search</button>
                    <i className='bx bx-search'></i>
                </div>
            </div>
            {
                (!loading && !categoriesList.length > 0 && cookie.get(MY_LOCATION)) && <div className='d-flex flex-column justify-content-center align-items-center'>
                    <span>Or</span>
                    <br></br>
                    <button 
                        disabled={locationError || loading}
                        className='btn btn-purple'
                        onClick={async ()=>{
                          
                                props.setBbox({
                                    longitude: JSON.parse(cookie.get(MY_LOCATION)).longitude, 
                                    latitude: JSON.parse(cookie.get(MY_LOCATION)).latitude, 
                                    boundingRadius: 40233
                                })
                               
                        }}
                    >
                        Use my current location
                    </button>
                </div>
            }
            {
                loading ? <CircleSpinner fullScreen={false} radius={50} /> :(
                    <div className='d-flex justify-content-center flex-column mb-4'>
                        {
                            (debouncedSearchTerm && categoriesList.length===0) && <div className='align-items-center flex-column justify-content-center d-flex'>
                                <SVG 
                                    src={toAbsoluteUrl("/static/icons/search-error.svg")}
                                    style={{width:'120px', maxWidth: '80%'}}
                                />
                                <small className='text-bold'>No results found</small>
                            </div>
                        }
                        {
                            categoriesList && categoriesList.length > 0 && (
                                <ul className='deals-result'>
                                    {
                                        categoriesList && categoriesList.map( x => <li 
                                            className='pl-4'
                                            onClick={()=>props.setBbox(x.bbox)}
                                        >
                                            {x.place_name}
                                        </li>)
                                    }
                                </ul>
                            )
                        }
                    </div>
                )
            }
        </>
    )
}


const DaySelector = (props) => {

    const [ showInput, setShowInput ] = useState(false)

    const [ nextDays, setNextDays ] = useState([]) 
    const [ inputDateValue, setInputDateValue ] = useState('') 
    const [ inputError, setInputError ] = useState(false) 

    const handleSetDay = (date) => {
        if(moment(date).add(1, 'days').isBefore(moment())){
            setInputError('Date must be higher than current date')
        } else if(!date){
            setInputError('Please set a date')
        } else{
            props.setDay(date)
        }

    } 

    useEffect(() => {
        
        let days = []

        for(let i=1; i<7; i++){
            days[i-1] = {
                date: moment().add(i+1, 'days').format('YYYY-MM-DD'),
                label: moment().add(i+1, 'days').format('dddd, MMMM D')
            }
        }

        setNextDays(days)

    }, [])

    return(
        <>
            <div className='card-body pl-4 pr-4'>
                {/* <p className='normal-size'><strong>Select the day</strong></p> */}
            </div>
            {
                <div className='d-flex justify-content-center flex-column mb-4 pl-4 pr-4'>
                    <span><strong>Select a day</strong></span><br/>
                    <div className='row' style={{fontSize:'1.25rem'}}>
                        <div className='col-6'>
                            <div className='day-card bordered cursor-pinter' onClick={()=>handleSetDay(moment().format('YYYY-MM-D'))}>
                            <div className=' row' >
                            
                                <div className='col-12'>
                                    <div className=' d-flex flex-column justify-content-center text-center h-100 pl-4 pr-4 pt-3 pb-3'>
                                      
                                        {moment().format('MMMM D')}
                                        <small className='day-text text-muted'><strong>Today</strong></small>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='day-card bordered cursor-pinter' onClick={()=>handleSetDay(moment().add(1, 'days').format('YYYY-MM-D'))}>
                            <div className=' row'>
                               
                                <div className='col-12'>
                                    <div className=' d-flex flex-column justify-content-center text-center h-100  pl-4 pr-4 pt-3 pb-3'>
                                       
                                        {moment().add(1, 'days').format('MMMM D')}
                                        <small className='day-text text-muted'><strong>Tomorrow</strong></small>
                                        {/* <span className='desc-text'>I'll keep at home today</span> */}
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                    {/* {
                        <ul className='deals-result'>
                            {
                                daysOptions && daysOptions.map( x => <li 
                                    className='pl-4'
                                    onClick={()=>props.setDay(x)}
                                >
                                    {x.label}
                                </li>)
                            }
                        </ul>
                    } */}
                </div>
            }
            <div className='d-flex justify-content-center flex-column mb-4'>
                <ul className='deals-result-grid-2'>
                    {
                        nextDays.length > 0 && nextDays.map( x => <li 
                            className='pl-4'
                            onClick={()=>handleSetDay(x.date)}
                        >
                            {
                                x.label
                            }
                        </li> )
                    }
                </ul>
            </div>
            {/* <hr /> */}
            
            <div className='pl-4 pr-4 mb-4'>
                {
                    !showInput ? <div className='d-flex justify-content-center'>
                        <button className='default-btn' onClick={()=>setShowInput(true)}>Or Choose a date manually</button>
                    </div> : <>
                        <label>Choose a date manually</label>
                        <div className='d-flex'>
                            <input className={`form-controla mr-3 ${inputError ? 'is-invalid' : ''}`} type='date' value={inputDateValue} onChange={(e)=>setInputDateValue(e.target.value)}/>
                            <button className='default-btn' onClick={()=>handleSetDay(inputDateValue)}>Set</button>
                        </div>
                        {
                            inputError &&  <div className="invalid-feedback d-flex">
                                {inputError}
                            </div>
                        }
                    </>
                }
                {
                    
                }
                <br/>
                {/* <small><strong>Note: </strong>Deals may be disabled at any time. here more text</small> */}
            </div>
        </>
    )
}
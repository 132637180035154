import React, { useState, useEffect, useRef } from 'react'
import ModalController from './Controller'

import distance from "@turf/distance";
import { useRouter } from 'next/router'
import { pointstransactionService } from '../../services';
import WeightSanitizer from '../Common/WeightSanitizer'
import useCart from '../../utils/useCart';
import { useSelector } from 'react-redux';
import cookie from 'js-cookie'
import { MY_LOCATION } from '../../helpers/constTypes';
import ImageUrlFormatter from '../Common/ImageUrlFormatter';
import Image from 'next/image';
import isEmpty from '../../utils/isEmpty';

const AddToCartModal = (props) => {

    const [ getCart, add ] = useCart()

    const data = useSelector(state => state.cartModal)

    const user = useSelector(state => state.user)

    const handleAddToCart = ({reward, quantity = 1}) => {


        const productToCart = {
            product:{
                brand: data.product.brand,
                category: data.product.category,
                name: data.product.name,
                picture: data.product.picture,
                isMedical: data.product.nearestRetailer && data.product.nearestRetailer.isMedical,
                slug: data.product.slug,
                _id: data.product._id,
                variant: selectedVariant,
            },
            quantity,
            dispensary: data.dispensary._id,
            reward
        }
        console.log(productToCart)
        add(productToCart)
    }

    const _closeModal = () => {
        childRef.current.closeModalFromController()
    }

    

    const incrementItem = () => {
        setQty(qty < max ? qty + 1 : qty);
    }

    const decreaseItem = () => {
        setQty(qty > 1 ? qty - 1 : qty);
    }
    
    const childRef = useRef();

    const router = useRouter()
    const [ actionsLoading, setActionsLoading ] = useState(false)
    const [ canConfirm, setCanConfirm ] = useState(false)
    const [ canConfirmNugs, setCanConfirmNugs ] = useState(false)
    const [ balance, setBalance ] = useState(0)
    const [ qty, setQty ] = useState(1)
    const [ buyForNugs, setBuyForNugs ] = useState(false)
    const [ selectedVariant, setSelectedVariant ] = useState(data.selectedVariant)
    const min = 1
    const max = 10

    const verifyBalance = async () => {
        let dispensary = data.dispensary._id
        let { balance } = await pointstransactionService.findOne(dispensary)
        setBalance(balance)
        setActionsLoading(false)
    }

    useEffect(() => {
        
        if(!isEmpty(data)){
            if(selectedVariant.price){
                setCanConfirm(true)
            } else  {
                setCanConfirm(false)
            }
            if(selectedVariant.isWPointsSalable){
                if(balance >= selectedVariant.WPointsValue){
                    setCanConfirmNugs(true)
                } else {
                    setCanConfirmNugs(false)
                }
            } else {
                setCanConfirmNugs(false)
            }
            setQty(1)
        }
        
    }, [data, selectedVariant])


    useEffect(() => {
        if(buyForNugs){
            setCanConfirm(false)
            if(balance >= selectedVariant.WPointsValue * qty){
                setCanConfirmNugs(true)
            } else {
                setCanConfirmNugs(false)
            }
        }
    }, [buyForNugs, qty])

    useEffect(() => {
        if(!data.checkBalance){
            setActionsLoading(true)
            setCanConfirm(true)
        } else {
            if(!isEmpty(user)){
                verifyBalance()
            }
        }
    }, [data])

    
    return (
        <ModalController active={props.active} closeModal={props.onClick} ref={childRef}>
            <div className='card-head w-100 d-flex justify-content-between pt-4 pl-4 pr-4 pb-4 bottom-bordered'>
                <div className='d-flex flex-column'>
                    
                    <h4 className='mb-0'>Add to cart</h4>
                    <div className='close-modal-black' style={{zIndex:'2'}}>
                                    <i className='bx bx-x cursor-pointer' onClick={props.onClick}></i>
                                </div>
                </div>
            </div>
            
            <div className="card-body pl-4 pr-4">
                {
                    (isEmpty(data)) ? 'Loading...' : (
                        <>
                            
                            
                            
                         
                            <div className="alert alert-info d-flex flex-column" role="alert">
                                <span style={{fontSize:'16px', fontWeight:'600'}}><strong>{data.dispensary.name} </strong></span>
                                <span className='d-flex align-items-center'><i className='bx bx-store-alt'></i>&nbsp;<small><strong className='capitalize'>{data.dispensary.type.toLowerCase()}</strong></small></span>
                                <span className='d-flex align-items-center'>
                                    <i className='bx bx-map'></i> &nbsp;
                                    {
                                        (cookie.get(MY_LOCATION)) && (
                                            
                                                data.product.nearestRetailer ? (
                                                    
                                                    data.product.nearestRetailer.location.coordinates && <small className='strinking'>{
                                                        distance(
                                                            {
                                                                "type": "Feature",
                                                                "properties": {},
                                                                "geometry": {
                                                                    "type": "Point",
                                                                    "coordinates": [JSON.parse(cookie.get(MY_LOCATION)).longitude, JSON.parse(cookie.get(MY_LOCATION)).latitude]
                                                                }
                                                            }, 
                                                            {
                                                                "type": "Feature",
                                                                "properties": {},
                                                                "geometry": {
                                                                    "type": "Point",
                                                                    "coordinates": [
                                                                        data.product.nearestRetailer.location.coordinates ? data.product.nearestRetailer.location.coordinates[0] : data.product.nearestRetailer.location[0] , 
                                                                        data.product.nearestRetailer.location.coordinates ? data.product.nearestRetailer.location.coordinates[1] : data.product.nearestRetailer.location[1] , 
                                                                    ]
                                                                }
                                                            },
                                                            { units: 'miles' }).toFixed(1)
                                                    }mi away</small>
                                                
                                                ) : (
                                                    data.dispensary.location.coordinates && <small className='strinking'>{
                                                        distance(
                                                            {
                                                                "type": "Feature",
                                                                "properties": {},
                                                                "geometry": {
                                                                    "type": "Point",
                                                                    "coordinates": [JSON.parse(cookie.get(MY_LOCATION)).longitude, JSON.parse(cookie.get(MY_LOCATION)).latitude]
                                                                }
                                                            }, 
                                                            {
                                                                "type": "Feature",
                                                                "properties": {},
                                                                "geometry": {
                                                                    "type": "Point",
                                                                    "coordinates": [
                                                                        data.dispensary.location.coordinates ? data.dispensary.location.coordinates[0] : data.dispensary.location[0] , 
                                                                        data.dispensary.location.coordinates ? data.dispensary.location.coordinates[1] : data.dispensary.location[1] , 
                                                                    ]
                                                                }
                                                            },
                                                            { units: 'miles' }).toFixed(1)
                                                    }mi away</small>
                                                )
                                            
                                        )

                                        
                                    }
                                    
                                    
                                </span>
                            </div>
                            
                            
                        

                            {/* <span className='badge badge-purple'>{data.product.type}</span>
                            {
                                data.product.strain && <span className='ml-1 badge badge-purple' style={{ textTransform:'uppercase'}}>{data.product.genetics}</span>
                            }
                            <br /> */}
                        
                            <h4 style={{fontSize:'16px', marginTop:'10px', textTransform:'uppercase'}}>{data.product.name}</h4>

                            <div>
                                <div className='row'>
                                    <div className='col col-3 col-md-4'> 
                                        {
                                            data.product.picture && data.product.picture.length > 0 ? (
                                                <Image 
                                                    alt={`${data.product.name} picture.`}
                                                    src={data.product.picture[0].replace('.jpg','').replace('.png','')}
                                                    
                                                    width={150}
                                                    height={150}
                                                    quality='75'
                                                /> 
                                            ) : (
                                                <Image 
                                                    alt='Default image placeholder.' 
                                                    width={150}
                                                    height={150}
                                                    
                                                    loader={({src, width, quality})=> {return `${src}?w=${width}&q=${quality || 75}`}}  
                                                    src={'/static/_images/default-pic-small.jpg'}  
                                                ></Image>
                                            )
                                        }
                                        
                                    </div>
                                    <div className='col col-md-8 pt-1'>

                                        {
                                            data.product.brand && <><span><strong>Brand:</strong> {data.product.brand.name}</span><br /></>
                                        }
                                        {
                                            data.product.category && <><span><strong>Category:</strong> {data.product.category.name}</span><br /></>
                                        }
                                        {
                                            data.product.strain && <><span><strong>Strain:</strong> {data.product.strain.name}</span><br /></>
                                        }
                                        {
                                            data.product.nearestRetailer && (data.product.nearestRetailer.isMedical && !data.product.nearestRetailer.isRecreational) && <><span className='text-info'>Medical Patients Only</span><br /></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <hr />
                                    
                                {
                                    data.variants.find(x => x.price > 0) && <>
                                        {/* <span><strong>Variants:</strong></span> */}
                                        <div className='row'>
                                        {
                                            data.variants.filter(x => x.stock != false).filter(x => x.price > 0).map(x => <div className='col col-md-3 col-4'>
                                                <div 
                                                    className={` bordered pt-1 pb-1 pl-2 pr-2 mb-2 cursor-pointer modal-variant-opt ${selectedVariant._id === x._id ? 'selected' : ''}`}
                                                    onClick={()=>
                                                        {
                                                            setSelectedVariant(x)
                                                            setBuyForNugs(false)
                                                        }

                                                    }
                                                >
                                                    <small>
                                                        <WeightSanitizer 
                                                            eachDetails={true}
                                                            type={x.type} 
                                                            unit={x.unit} 
                                                            _weight={x.weight} 
                                                            eachDetails={true}
                                                        />
                                                    </small>
                                                    <div>
                                                        {
                                                            x.price && <span>${x.price}</span>
                                                        }
                                                        {
                                                            x.price && x.isWPointsSalable && <> | </>
                                                        }
                                                        {
                                                            x.isWPointsSalable && <span>{x.WPointsValue} Nugs</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>)
                                        }
                                        {
                                            !isEmpty(user) && data.variants.filter(x => x.stock != false).filter(x => x.isWPointsSalable).map(x => <div className='col col-md-3 col-4'>
                                                <div 
                                                    className={` bordered pt-1 pb-1 pl-2 pr-2 mb-2 cursor-pointer modal-variant-opt ${selectedVariant._id === x._id ? 'selected' : ''}`}
                                                    onClick={()=>{
                                                        setSelectedVariant(x)
                                                        setBuyForNugs(true)
                                                    }}
                                                >
                                                    <small>
                                                        <WeightSanitizer 
                                                            type={x.type} 
                                                            unit={x.unit} 
                                                            _weight={x.weight} 
                                                            eachDetails={true}
                                                        />
                                                    </small>
                                                    <div>
                                                        {
                                                            x.price && <span>${x.price}</span>
                                                        }
                                                        {
                                                            x.price && x.isWPointsSalable && <> | </>
                                                        }
                                                        {
                                                            x.isWPointsSalable && <span>{x.WPointsValue} Nugs</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>)
                                        }
                                        </div>
                                    </>
                                }
                                {/* {
                                    data.variants.find(x => x.isWPointsSalable > 0) && <>
                                        <span><strong>Get as reward:</strong></span>
                                        <div className='row'>
                                        
                                        </div>
                                    </>
                                } */}

                            {/* <hr/> */}
                            <div >
                                <span><strong>Quantity</strong></span><br/>
                                <div className="input-counter">
                                    {
                                        qty>min && <span className="minus-btn" onClick={(decreaseItem)}>
                                            <i className='bx bx-minus'></i>
                                        </span>
                                    }
                                    <input 
                                        type='number' 
                                        value={qty}
                                        min={min}
                                        max={max}
                                        readOnly='readonly'
                                        onChange={e => setQty(e.target.value)}
                                    />
                                    {
                                        qty<max && <span className="plus-btn" onClick={incrementItem}>
                                            <i className='bx bx-plus'></i>
                                        </span>
                                    }
                                </div>
                            </div>
                        </>
                    )
                }
            

            </div>
            <div className="card-footer pl-4 pr-4 justify-content-between d-flex flex-column">
                {
                    (isEmpty(data)) ? 'Loading...' : (
                        <>
                            {
                                !isEmpty(user) && <span>
                                {
                                    data.checkBalance && <span className={`${selectedVariant.WPointsValue > balance ? 'text-warning' : ''}`}>{
                                        actionsLoading ? 'Loading...' : (
                                            <>
                                                {
                                                     `Your Nugs balance is: ${balance}`
                                                }
                                            </>

                                        )
                                    }</span>
                                }
                            </span>
                            }
                            
                            <div className='d-flex align-items-end justify-content-end'>
                            <button 
                                        className='optional-btn mr-1' 
                                        type='button'
                                        // disabled={!canConfirm || !canConfirmNugs}
                                        onClick={
                                            ()=>{
                                                _closeModal()
                                            }
                                        }
                                    >
                                        Cancel
                                    </button>
                            {
                             
                                    (canConfirm || canConfirmNugs) && <button 
                                        className='default-btn' 
                                        type='button'
                                        // disabled={!canConfirm || !canConfirmNugs}
                                        onClick={
                                            ()=>{
                                                handleAddToCart({reward:buyForNugs, quantity: qty})
                                                _closeModal()
                                            }
                                        }
                                    >
                                        Confirm
                                    </button>
                                
                            }
                            </div>
                            
                        </>
                    )
                }
            </div>
        </ModalController>
    )
}

export default AddToCartModal
import React, { useState, useEffect, useRef } from 'react';
import SidebarCheckFilter from '../Sidebars/SidebarCheckFilter'
import SidebarRangeFilter from '../Sidebars/SidebarRangeFilter'
import SidebarStaticRangeFilter from '../Sidebars/SidebarStaticRangeFilter'
import { multiQueryStringify, filterParse } from '../../helpers/theQueryString'
import queryString from "query-string"
import { categoryService } from '../../services';
import { detectActive, parse as contextQueryParse } from '../../utils/contextQuery'
import Link from 'next/link';
import { useSelector, useDispatch } from 'react-redux';
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl'
;
import { updateModalStatus } from '../../store/actions/cartActions';
import { MY_LOCATION_PLACE } from '../../helpers/constTypes';
import cookie from 'js-cookie'
import isEmpty from '../../utils/isEmpty';

const Menu = (props) => {

    const dispatch = useDispatch()

    const openModal = (modal)=>{
        
        dispatch(updateModalStatus({[modal]:true}));
    }

    const user = useSelector(state => state.user)

    const [ active, setActive ] = useState(false)

  

    useEffect(() => {
        if(props.active === 'active'){
            setTimeout(() => {
                setActive(true)
            }, 10);
        }
    }, [props.active])

    const closeThis = () => {
        setActive(false);
        setTimeout(() => {
            props.onClick()
        }, 300)
    }


    const shouldCloseModal = async  (e) => {
        if(typeof e.target.className ==='string'){
            (e.target.className.includes('sidebarModal')) && closeThis()
        }
    };

    return (
        <React.Fragment>
            <div onMouseDown={(e) => shouldCloseModal(e)} className={`sidebarModal left ${active ? 'active' : ''}`} > 
                <div className="modal-innter-content main-menu" >
                    <div className="modal-body p-0" style={{overflowX:'hidden'}}>
                        <div className=' position-relative mt-3 mb-1'>
                            <div className='container-fluid'>
                                <h3 className='mb-0 pb-0'>Menu</h3>
                            </div>
                            {/*<hr />*/}
<br/>
                  
                            
                            <button style={{top:'-3px', right:25}} type="button" className="close" onClick={closeThis}>
                                <span aria-hidden="true">
                                    <i className='bx bx-x'></i>
                                </span>
                            </button>
                        </div>
                        <div className=' mb-1'>
                                <a href='https://enterprise.weedzly.com/' target='_blank' className='btn btn-link'>Advertise With Weedzly</a>
                            </div>
                            {
                                (isEmpty(user)) && (
                                    <>
                                        <small className='text-bold menu-header ml-2 text-dark' style={{fontSize:'10px'}}>ACCOUNT</small> 
                                        <div className=''>
                                            
                                            <ul className='mt-2'>
                                                <li 
                                                    onClick={
                                                        ()=>{
                                                            closeThis()
                                                            openModal('login')
                                                        }
                                                    }
                                                >
                                                    <a className='pl-4 pr-4 side-marker pr-4 d-flex cursor-pointer align-items-center '>
                                                        <i style={{fontSize:'16px'}} className='bx bx-log-in mr-3'></i>
                                                        Sign in
                                                    </a>
                                                </li>
                                                <li 
                                                    onClick={()=>{
                                                        openModal('signup')
                                                        closeThis()
                                                    }}
                                                >
                                                    <a className='side-marker pl-4 pr-4 d-flex cursor-pointer align-items-center '>
                                                        <i style={{fontSize:'16px'}} className='bx bx-user-plus mr-3'></i>
                                                        Sign up
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        {/*<hr />*/}
<br/>
                                    </>
                                )
                            }
                            
                            <div className=' mb-4'>

                               
                                <small className='text-bold menu-header ml-2' style={{fontSize:'10px'}}>DISCOVER</small>
                                <ul className='mt-2'>
                                  
                                    
                                    <li>
                                        <Link 
                                            prefetch={false} 
                                            href='/dispensaries' 
                                            as='/dispensaries' 
                                            className={`
                                                pl-4 pr-4 side-marker pr-4 d-flex cursor-pointer align-items-center 
                                                ${location.pathname.substr(0, '/dispensaries'.length)  === '/dispensaries' && !location.pathname.includes('/deliveries') ? 'active' : ''}
                                            `}
                                            onClick={
                                                ()=>closeThis()
                                            }
                                        >
                                            <>
                                                <div className='circled icon d-flex align-items-center justify-content-center'>
                                                    <i style={{fontSize:'16px'}} className='bx bx-store-alt mr-3'></i>
                                                </div>
                                                <span>Dispensaries</span>
                                            </>
                                        </Link>
                                    </li>
                                    {
                                        cookie.get('IS_DELIVERY_AVAILABLE') === 'true' && (
                                            <li>
                                                <Link 
                                                    prefetch={false} 
                                                    href='/deliveries' 
                                                    as='/deliveries' 
                                                    className={`
                                                            pl-4 pr-4 side-marker pr-4 d-flex cursor-pointer align-items-center 
                                                            ${location.pathname.substr(0, '/deliveries'.length) === '/deliveries' ? 'active' : ''}
                                                        `}
                                                    onClick={
                                                        ()=>closeThis()
                                                    }

                                                >
                                                    <>
                                                        <i style={{fontSize:'16px'}} className='bx bx-car mr-3'></i>
                                                        <span>Deliveries</span>
                                                    </>
                                                </Link>
                                            </li>
                                        )
                                    }
                                    <li>
                                        <Link 
                                            prefetch={false} 
                                            href="/deals/[state]" 
                                            as={`/deals/${cookie.get('REGION').toLowerCase()}`}
                                            className={`
                                                pl-4 pr-4 side-marker pr-4 d-flex cursor-pointer align-items-center 
                                                ${location.pathname.substr(0, '/deals'.length) === '/deals' ? 'active' : ''}
                                            `}
                                            onClick={
                                                ()=>closeThis()
                                            }
                                        >
                                            <>
                                                <i style={{fontSize:'16px'}} className='bx bx-purchase-tag mr-3'></i>
                                                <span>Deals</span>
                                            </>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                            prefetch={false} 
                                            href='/brands' 
                                            as='/brands'
                                            className={`
                                                pl-4 pr-4 side-marker pr-4 d-flex cursor-pointer align-items-center 
                                                ${location.pathname.substr(0, '/brands'.length) === '/brands' ? 'active' : ''}
                                            `}
                                            onClick={
                                                ()=>closeThis()
                                            }
                                        >
                                            <>
                                                <i style={{fontSize:'16px'}} className='bx bx-tag mr-3'></i>
                                                <span>Brands</span>
                                            </>
                                        </Link>
                                    </li>
                                    
                                    <li>
                                        <Link 
                                            prefetch={false} 
                                            href='/products' 
                                            as='/products'
                                            className={`
                                                pl-4 pr-4 side-marker pr-4 d-flex cursor-pointer align-items-center  
                                                ${location.pathname.substr(0, '/products'.length) === '/products' ? 'active' : ''}
                                            `}
                                            onClick={
                                                ()=>closeThis()
                                            }
                                        >
                                            <>
                                                <i style={{fontSize:'16px'}} className='bx bx-basket mr-3'></i>
                                                <span>Products</span>
                                            </>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                            prefetch={false} 
                                            href='/strains' 
                                            as='/strains'
                                            className={`
                                                    side-marker pr-4 pl-4 d-flex cursor-pointer align-items-center 
                                                    ${location.pathname.substr(0, '/strains'.length) === '/strains' ? 'active' : ''}
                                                `}
                                                onClick={
                                                    ()=>closeThis()
                                                }
                                        >

                                            
                                            <i style={{fontSize:'16px'}} className='bx bx-dna mr-3'></i>
                                            <span>Strains</span>
                                       
                                        </Link>
                                    </li>
                                </ul>
                                {/*<hr />*/}
<br/>
                                <small className='text-bold menu-header ml-2' style={{fontSize:'10px'}}>EXPLORE</small>
                                <ul className='mt-2'>
                                    
                                    <li>
                                        <Link prefetch={false} 
                                            href={'/map'} 
                                            as={'/map'} 
                                            className={`
                                                side-marker pr-4 d-flex cursor-pointer align-items-center pl-4 pr-4
                                                ${location.pathname.substr(0, '/map'.length) === '/map' ? 'active' : ''}
                                            `}
                                            onClick={
                                                ()=>closeThis()
                                            }
                                            
                                        >
                                            <>
                                                <i style={{fontSize:'16px'}} className='bx bx-map-alt mr-3'></i>
                                                <span>Map</span>
                                            </>
                                        </Link>
                                    </li>
                                </ul>
                                {/*<hr />*/}
<br/>
                                <small className='text-bold menu-header ml-2' style={{fontSize:'10px'}}>LEARN</small>
                                <ul className='mt-2'>
                                    
                                    <li>
                                        <Link 
                                            prefetch={false} href='/library' 
                                            as='/library'
                                            className={`
                                                side-marker pr-4 d-flex cursor-pointer align-items-center pl-4 pr-4
                                                    ${location.pathname.substr(0, '/library'.length) === '/library' ? 'active' : ''}
                                            `}
                                            onClick={
                                                ()=>closeThis()
                                            }
                                        >
                                            <>
                                                <i style={{fontSize:'16px'}} className='bx bx-library mr-3'></i>
                                                <span>Library</span>
                                            </>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                            prefetch={false} 
                                            href='/glossary' 
                                            as='/glossary'
                                            className={`side-marker pr-4 d-flex cursor-pointer align-items-center pl-4 pr-4 ${location.pathname.substr(0, '/glossary'.length) === '/glossary' ? 'active' : ''}`}
                                            onClick={
                                                ()=>closeThis()
                                            }
                                        >
                                            <>
                                                <i style={{fontSize:'16px'}} className='bx bx-book mr-3'></i>
                                                <span>Glossary</span>
                                            </>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
           
        </React.Fragment>
    );
}

export default Menu
import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux';
import { userLogin } from '../../store/actions/cartActions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import { accountService } from '../../services';
import * as Yup from 'yup';
import useCart from '../../utils/useCart';
import { updateModalStatus } from '../../store/actions/cartActions';

const LoginForm = (props) => {

    const router = useRouter()

    const openModal = (modal)=>{
        dispatch(updateModalStatus({[modal]:true}));
    }
    
    const dispatch = useDispatch()
    const [
        get,
        add,
        remove,
        update,
        sync
    ] = useCart()

    

    const initialValues = {
        email: '',
        password: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        password: Yup.string().required('Password is required')
    });

    function onSubmit({ email, password }, { setSubmitting }) {
        accountService.login(email, password)
            .then( async (response) => { 
                if(response.response) {
                    await localStorage.setItem('user', JSON.stringify({token:response.response}));
                    accountService.getMe(response.response)
                        .then( async (userData) => {
                            if(userData.type!='CUSTOMER'){
                                toast.error('Invalid user', {
                                    position: "top-center",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                setSubmitting(false);
                            } else {
                                await dispatch(userLogin({...userData, token:response.response}))
                                await sync()
                                window.location.reload()
                            }
                        })
                } else {
                   if(response.data.message){
                        toast.error(response.data.message, {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error(response.data.errors[0].message, {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    setSubmitting(false);

                } 
                
            })
            .catch(error => {
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setSubmitting(false);
            });
    }

    return(
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting }) => (
                <>
                    <ToastContainer/> 
                    <h2 className='mb-1'><center>Login</center></h2> 
                    {/* <span className='subtitle text-muted'><center>Get your weed now!</center></span> */}
                    <Form className="login-form mt-3">
                        <div className="form-group">
                            <Field name="email" type="email" placeholder="Email" className={'form-controla' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <Field name="password" type="password" placeholder="Password" className={'form-controla' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                        </div>
                        <button type="submit" disabled={isSubmitting} className="default-btn btn-block">
                                {isSubmitting ? <span className="spinner-border spinner-border-sm mr-1"></span> : "Login"}
                        </button>
                        <div className="text-center mt-3">
                            <button className="forgot-password btn btn-link pl-0 pr-0 mr-2" type='button' onClick={props.page ? ()=>router.push('/forgot-password') : ()=>{props.closeModal(); router.push('/forgot-password', '/forgot-password')}}>Forgot your password?</button>
                            <a className="forgot-password" onClick={props.page ? ()=>router.push('/signup') : ()=>{props.closeModal(); openModal('signup')}} >Sign up instead</a> 
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    )

}

export default LoginForm
const moment = require('moment-timezone')

export const checkIfExpired = (date) => {
    const end = moment(date);
    const start = moment();
    const diff = end.diff(start)
    if(diff<0){
        return true
    } else {
        return false
    }
}

export const getDayAndTime = (firtParam=false) => {
    let curtime = moment().diff(moment('00:00', 'HH:mm'), "minutes")+(moment().tz('America/Los_Angeles')._offset-moment().utcOffset())
    
    let day = moment.tz().format('dddd').toUpperCase()

    if(curtime < 0){
        day = moment.tz().subtract(1, 'days').format('dddd').toUpperCase()
        curtime = 1440 - curtime
    } else if(curtime > 1439){
        day = moment.tz().add(1, 'days').format('dddd').toUpperCase()
        curtime = curtime - 1440
    }


    return `${firtParam ? '?' : '&'}day=${day}&time=${curtime}`
}
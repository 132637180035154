import React, { Component, useEffect, useState } from 'react';
import { notificationService } from '../../services'

import { toAbsoluteUrl } from '../../utils/toAbsoluteUrl';
import SVG from "react-inlinesvg";
import ImageUrlFormatter from '../Common/ImageUrlFormatter'
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component'
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, updateModalStatus } from '../../store/actions/cartActions';
import { useRouter } from 'next/router';
import Image from 'next/image';
import isEmpty from '../../utils/isEmpty';


const hookClass = (props) => {

    const closeModal = () => {
        props.onClick(false);
    }

    const [ actionsLoading, setActionsLoading ] = useState(false)

    const { notifications } = useSelector(state => state)
    const dispatch = useDispatch()

    const shouldCloseModal = async  (e) => {
        if(typeof e.target.className ==='string'){
            (e.target.className.includes('shoppingCartModal')) && closeModal()
        }
    }

    const _updateNotifications = async (a) => {
        try {

            
            setActionsLoading(true)
            let _notifications = await notificationService.get('?sortField=createdAt&sortOrder=desc&size=10&from='+notifications.totalData.length)
            
            const notificationsList = notifications.totalData && notifications.totalData.length > 0 ? notifications.totalData.concat(_notifications.totalData) : _notifications.totalData

            _notifications.totalData = notificationsList
  
            let hasMore = null

          
            if(_notifications.totalCount.length > 0){

              
                hasMore = notifications.totalData.concat(_notifications.totalData).length <= _notifications.totalCount[0].count
            }
        
            dispatch(getNotifications({..._notifications, hasMore, fetched: true}))
            setActionsLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        
        if(!notifications.fetched){
            _updateNotifications()
        }
        
    }, [])

    const updateANotification = (list) => {
      
        let _notifications = notifications
        _notifications.totalData = list
     
        dispatch(getNotifications({..._notifications, noReaded: _notifications.noReaded - 1}))
    }

    return (
        <div onMouseDown={(e) => shouldCloseModal(e)} className={`shoppingCartModal right ${props.active}`}> 
     
            <NotificationSideModal {...props} closeModal={closeModal} actionsLoading={actionsLoading} notifications={notifications} updateANotification={updateANotification}  _updateNotifications={_updateNotifications} />
        </div>
    )

}

class NotificationSideModal extends Component {


  
    markAsReaded = async (id) => {
        try {
            await notificationService.update(id, {readed: true})
            this.props.updateANotification(this.props.notifications.totalData.map(x => {
                if(x._id === id) x.readed=true
                return x
            }))
        } catch (err) {
            console.log(err)
        }
    }
    

    escFunction = (e) => {
        if(e.keyCode === 27){
            this.props.closeModal()
        }
      }

    componentDidMount (){
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
      }

    
    render() {
        
        return (
            <React.Fragment>
                    
                    <div className="modal-innter-content">
                        <button type="button" className="close" onClick={this.props.closeModal}>
                            <span aria-hidden="true">
                                <i className='bx bx-x'></i>
                            </span>
                        </button>
                        <div className='modal-body pb-0 pt-4'>
                                <h3 className='mb-0'>Notifications</h3>
                            </div>
                            <div className='modal-sidebar-body' id='scrollable'>
                            <ul className='notifications'>
                                {
                                    (this.props.notifications.totalData && this.props.notifications.totalData.length === 0 && this.props.isActionLoading) ? (
                                        <LoadingNotificationItem />
                                    ) : (this.props.notifications.totalData && this.props.notifications.totalData.length === 0 && this.props.notifications.fetched) ? (
                                        <div className='d-flex justify-content-center flex-column align-items-center'>
                                            
                                            <Image 
   
   alt="A strikethrough bell." 

                                              width={100}
                                              height={100}
                                               loader={({src, width, quality})=> {return `${src}?w=${width}&q=${quality || 75}`}}  
                                               src={'/static/images/no-notifications.png'} 
                                            ></Image>
                                            <h4 style={{fontSize:'17px'}} className='mt-3'>You have no notifications to show</h4>
                                        </div>
                                    ) : (
                                        <InfiniteScroll
                                            dataLength={this.props.notifications.totalData.length}
                                            next={this.props._updateNotifications}
                                            hasMore={this.props.notifications.hasMore}
                                            loader={
                                                <LoadingNotificationItem />
                                            }
                                            scrollableTarget='scrollable'
                                            endMessage={
                                                <p className='pt-2 pb-2' style={{ textAlign: "center" }}>
                                                    <b>There is no more notifications</b>
                                                </p>
                                            }
                                        >
                                            
                                            {
                                                this.props.notifications.totalData && this.props.notifications.totalData.map((x, i)=> 
                                                    <li 
                                                        key={i}
                                                        style={{paddingRight:'15px', paddingLeft:'15px'}} 
                                                        className={`pt-3 pb-3 ${x.readed ? 'readed' : ''}`}
                                                        onClick={()=>this.markAsReaded(x._id)}
                                                    >
                                                        <NotificationItem 
                                                            item={x}
                                                        />
                                                    </li>
                                                )
                                            }
                                        </InfiniteScroll>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
        
            </React.Fragment>
        );
    }
}

export default hookClass


const LoadingNotificationItem = () => { 

    return (
        <div 
            className='pt-2 d-flex pb-2'
            style={{paddingRight:'15px', paddingLeft:'15px'}}
        >
            <Skeleton height={42} width={42} className='mr-3' style={{borderRadius:'100%'}} />
            <div style={{width:'100%'}}>
                <Skeleton height={50} /><br/>
                <div style={{width:'50%'}}>
                    <Skeleton height={10} />
                </div>
            </div>
            
        </div>
    )
}


const NotificationItem = (props) => {

    const [ state, setState ] = useState({})

    const dispatch = useDispatch()

    const _text = (status, orderNumber, mode) => {
        let body = ''
        switch (status) {
            case "ACCEPTED":
    
                if(mode === 'PICKUP'){
                    body = `Your Weedzly order #${orderNumber} for pickup has been accepted.`
                }
    
                if(mode === 'CURBSIDE_PICKUP'){
                    body = `Your Weedzly order #${orderNumber} for curbside pickup has been accepted.`
                }
    
                if(mode === 'DELIVERY'){
                    body = `Your Weedzly order #${orderNumber} has been accepted.`
                }
    
                break;
    
            case "REJECTED":
                body = `Your Weedzly order #${orderNumber} has been rejected.`
                break;
         
            case "READY":
                if(mode === 'PICKUP'){
                    body = `Your Weedzly order #${orderNumber} is ready for pickup.`
                } 
                if(mode === 'CURBSIDE_PICKUP'){
                    body = `Your weedzly order #${orderNumber} is ready for curbside pickup.`
                } 
                if(mode === 'DELIVERY'){
                    body = `Your Weedzly order #${orderNumber} is on the way.`
                } 
                break;
            case "DISPATCHED":
                body = `Your Weedzly delivery order #${orderNumber} is on the way.`
                break;
     
            default:
                break;
        }
        return body
    }

    useEffect(() => {
        let data = {
            href: '',
            as: '',
            picture: '',
            text: '',
            icon: '',
            createdAt: props.item.createdAt
        }
        
        switch ( props.item.type.toLowerCase()) {
            
            case 'pointstransaction':
                data.href = `/account/points/[dispensary]`
                data.as = `/account/points/${props.item.pointstransaction.dispensary._id}`
                data.picture =  props.item.transmitter && props.item.transmitter.type === 'ADMIN' 
                    ? '/static/_images/logo-small.png'
                    : props.item.transmitter && props.item.transmitter.picture 
                        ? ImageUrlFormatter(props.item.transmitter.picture, 'full', "64", "64")
                        : '/static/_images/user.png'
                data.text = ()=><>
                    {
                        props.item.transmitter && props.item.transmitter.type === 'ADMIN' 
                            ? <><span className='text-bold'>Your </span> receipt upload has been approved. <strong>+{props.item.pointstransaction.amount} nugs </strong>- get rewards at <strong>{props.item.pointstransaction.dispensary.name}</strong></>
                            : <><strong>@{props.item.transmitter ? props.item.transmitter.nickname : 'Deleted user'}</strong> sent you <strong>{props.item.pointstransaction.amount} nugs </strong>that can be used to <strong>get rewards</strong> at <strong>{props.item.pointstransaction.dispensary.name}</strong></> 
                    }
                    
                </>
                data.icon = '/static/icons/hoolding-leaf.svg'
                break;

            case 'order':
                data.href = `/account/orders/[id]`
                data.as = `/account/orders/${props.item.order._id}`
                data.picture = props.item.order.dispensary.logo 
                    ?   ImageUrlFormatter(props.item.order.dispensary.logo, 'full', "64", "64")
                    :   '/static/_images/logo-small.png'
                data.text = ()=> _text(props.item.orderStatus, props.item.order.number, props.item.order.mode)
                data.icon = '/static/icons/cart_purchase.svg'
                break;
        
            default:
                break;
        }


        setState(data)
    }, [props.item])

    const router = useRouter()
        

    if(isEmpty(state)) return ''
    return(
        
            <div className='d-flex'  onClick={()=>{
                dispatch(updateModalStatus({notification:false}))
                router.push(state.href, state.as)
            }}>
                {/* <iimg 
                    src = {state.picture}
                    className = 'mt-1 mr-3'
                /> */}
                <div className='w-100'>
                    <p className='notification-text'>{state.text()}</p>
                    <div className='d-flex align-items-start'>
                        <SVG src={toAbsoluteUrl(state.icon)} width={30} height={25} />
                        <small className='date text-muted ml-1'>{moment(state.createdAt).fromNow()}</small>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-end ml-1' style={{width:'12px'}}>
                    <span className={`${!props.item.readed ? 'active' : ''} readed-badge`}></span>
                </div>
            </div>
       
    )
    
}
import React, { useState, useRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import { searchQueryHandler } from './contextQuery'
import { getDayAndTime } from './Timing';

const ShallowPaginationContext = React.createContext({});

export const ShallowPaginationProvider = (props) => {


	const searchQuery = queryString.parse(window.location.search, {arrayFormat:'bracket'})

    const isFirstRun = useRef(true);
    const firstload = useRef(true);

	const router = useRouter()
    const [items, setItems] = React.useState([])
    const [scroll, setScroll] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [model, setModel] = React.useState('')
    // const [firstload, setFirstload] = React.useState(false)
    const searchInitialState = searchQuery.search
    const [ requiredParam, setRequiredParam ] = useState(false)
    const [search, setSearch] = React.useState(searchInitialState) 
    const [pageInfo, setPageInfo] = React.useState({
        count:'',
        currentPage:'',
        currentLength:'',
        pageSize:24,
        from:'',
        to:''
    })
    const [ links, setLinks ] = useState({})

    const addNewItems = ({
        items,
        searchQuery,
        requiredParam
    }) => {

        console.log(items);
       
        if(requiredParam){
            setRequiredParam(requiredParam)
        } else {
            setRequiredParam(false)
        }
      
        setItems(items ? items.totalData : []);
     
        setSearch(searchQuery.search || '');
        setPageInfo({
            ...pageInfo, 
            ...{
                count: items && items.totalCount.length>0 ? items.totalCount[0].count : 0, 
                currentPage: searchQuery.page ? parseInt(searchQuery.page, 10) : 1, 
                from: items ? items.from : 0,
                to: items ? items.to : 0,
                currentLength: items ? items.totalData.length : 0,
                pageSize: parseInt(searchQuery.size, 10) || pageInfo.pageSize,
            }
        })
        setLoading(false)
      
    }

	// const [ debouncedvalue ] = useDebounce(props.search, 1000);

    useEffect(() => { 
        if(requiredParam != false && window.location.pathname.includes('/deals/')){
            createQueryString()
        }
	}, [requiredParam])

 

	const createQueryString = async (
        page, 
        size, 
        query, 
        newFilter, 
        extraOpts,
        customBoundingRadius,
        resetFields
    ) => {

    
     
        window.scrollTo({
            top: document.getElementById('list').offsetTop-100,
            behavior: 'smooth'
        })
		
		let _query = searchQueryHandler(page, size, query, newFilter, searchQuery, extraOpts, resetFields)
        if(!_query.length>0 && requiredParam && requiredParam.length>0){
            _query = '?'
        }

        let _link = `${links.link}${_query}${requiredParam ? requiredParam : ''}`
        let _linkAs = `${links.linkAs}${_query}${requiredParam ? requiredParam : ''}${_query.includes('isOpen') ? getDayAndTime() : ''}`

   
       
        if(_linkAs.includes('boundingRadius') && customBoundingRadius){
        
            _linkAs = _linkAs
        } else if(customBoundingRadius){
            if(customBoundingRadius.boundingRadius){
                _linkAs = _linkAs.includes('?') ? _linkAs+`&boundingRadius=${customBoundingRadius.boundingRadius}` : _linkAs+`?boundingRadius=${customBoundingRadius.boundingRadius}`
            } else {
                _linkAs = _linkAs
            }
        }



       
        await router.push(
            _link, 
            _linkAs, 
            {
                scroll: false, 
                shallow:true
            }
        )
	}

    return (
        <ShallowPaginationContext.Provider value={{
            items, 
            pageInfo, 
            loading, 
            createQueryString, 
            setSearch, 
            firstload, 
            setItems,
            addNewItems,
            setLinks, 
            setLoading,
            links,
            setScroll,
            setModel,
            model,
            scroll,
            setRequiredParam
        }}>
            {props.children}
        </ShallowPaginationContext.Provider>
    )

};

export default ShallowPaginationContext 
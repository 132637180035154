import React, { useState  } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { accountService } from '../../services';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import { updateModalStatus, userLogin } from '../../store/actions/cartActions';
import { useDispatch } from 'react-redux';
import useCart from '../../utils/useCart';
import { Router, useRouter } from 'next/router';

const SignupForm = (props) => {

    const openModal = (modal)=>{
        dispatch(updateModalStatus({[modal]:true}));
    }
    const dispatch = useDispatch()

    const initialValues = {
        email: '',
        password: '',
        nickname: '',
        acceptTerms: false
    };

    const [
        get,
        add,
        remove,
        update,
        sync
    ] = useCart()

    const router = useRouter()

    const validationSchema = Yup.object().shape({
        nickname: Yup.string().max(20,'Max length is 20')
            .required('Nickname is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        acceptTerms: Yup.bool()
            .oneOf([true], 'Accept Terms & Conditions is required')
    });

    function onSubmit(fields, { setSubmitting }) {
        console.log(fields);
        accountService.register(fields)
            .then( async (response) => { 
                if(response.response) {
                    await localStorage.setItem('user', JSON.stringify({token:response.response}));
                    accountService.getMe(response.response)
                        .then( async (userData) => {
                            if(userData.type!='CUSTOMER'){
                                toast.error('Invalid user', {
                                    position: "top-center",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                setSubmitting(false);
                            } else {
                                await dispatch(userLogin({...userData, token:response.response}))
                                await sync()
                                window.location.reload()
                            }
                        })
                } else {
                   if(response.data.message){
                        toast.error(response.data.message, {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error(response.data.errors[0].message, {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                    setSubmitting(false);

                } 
                
            })
            .catch(error => {
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setSubmitting(false);
            });
    }
    

    const [ birthdateInput, setBirthdateInput ] = useState(false)

    const [ validNickname, setValidNickname ] = useState(false)
    const [ checkingNickname, setCheckingNickname ] = useState(false)

    const checkExists = async (value, setStatus, status) => {
        if(value.length > 0) {
            try{
                setCheckingNickname(true)
                let response = await accountService.findByNickname(value)
                setCheckingNickname(false)
                if(response._id){
                    setValidNickname(false)
                    
                    setStatus({
                        nickname: 'This nickname is already taken'
                    })
                } 
            } catch(err) {
                setCheckingNickname(false)
                if(err==='Not Found'){
                    let newStatus = Object.assign({}, status)
                    if(status && status.nickname==='This nickname is already taken'){
                        delete newStatus.nickname
                        setStatus(newStatus)
                    }
                }
            }
        }
    }

    return (
        <Formik  initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} >
        {({ errors, touched, isSubmitting, setFieldTouched, handleChange, setFieldValue, setFieldError, values, setStatus, status }) => (
            <>
                <ToastContainer />
                <h2 className='mb-1'><center>Join Weedzly</center></h2>
                <span className='subtitle text-muted'><center>Join now to find deals and save money on your next cannabis purchase</center></span>
                <Form className="signup-form mt-3">
                    <div className="form-group" style={{position:'relative'}}>
                        <Field  name="nickname"
                            className={'form-controla' + (errors.nickname && touched.nickname ? ' is-invalid' : '') + (status && status.nickname ? ' is-invalid' : '')}
							type="text"  
                            placeholder='Nickname'
                            onChange={(e) => {
                                let regUser = /^(?=.{0,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+$/
                                const value = e.target.value.trim() || '';
                                if(value.length > 0 && !regUser.test(value)){
                                    return false
                                } else {
                                    setFieldValue('nickname', value);
                                }
                            }}
                            onBlur={
                                (e)=>{
                                    checkExists(values.nickname, setStatus, status)
                                }
                            }
						/>
                        {
                            status && status.nickname ? (
                                <div className="invalid-feedback d-block">{status.nickname}</div>
                            ) : (
                                <ErrorMessage name="receiver" component="div" className="invalid-feedback" />
                            )
                        }
                        { 
                            checkingNickname && <span style={{position: 'absolute', right:'.5rem', top:'1rem'}} className="spinner-border spinner-border-sm mr-1"></span>
                        }
                    </div>
                    <div className="form-group">
                        <Field name="email" type="text" placeholder='Email' className={'form-controla' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" /> 
                    </div>
                    <div className="form-group">
                        <Field name="password" type="password" placeholder='Password' className={'form-controla' + (errors.password && touched.password ? ' is-invalid' : '')} />
                        <ErrorMessage name="password" component="div" className="invalid-feedback" /> 
                    </div>
                    <div className="form-group">
                        <div className="custom-control custom-checkbox">
                            <Field type="checkbox" id="newsletter-signup" name="acceptTerms" className={'custom-control-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                            <label htmlFor="newsletter-signup" className=" custom-control-label form-check-label">Accept Terms & Conditions</label><br/>
                            <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" /> 
                        </div>
                    </div>
                    <button type="submit" disabled={isSubmitting} className="default-btn btn-block mb-3">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Create new account
                    </button>
                    <span className=''><center>Do you have an account?</center></span>
                    <button type="button" disabled={isSubmitting}  onClick={props.page ? ()=>router.push('/login') : ()=>{props.closeModal(); openModal('login')}} className="optional-btn btn-block mt-3">
                        Login
                    </button>
                </Form>
            </>
            )}
        </Formik>
    )

}

export default SignupForm
import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react'
import Dropdown from 'react-dropdown';



const ModalController = forwardRef(({closeModal, active, children, wide, style, fullWidth, extrawide, menu, hideCloseButton}, ref)=> {

    const [ isActive, setIsActive ] = useState(false)
   
    const shouldCloseModal = async  (e) => {
       
        if(typeof e.target.className ==='string'){
            (e.target.className.includes('modal-wrapper') || e.target.className.includes('modal-overlay')) && _closeModal()
        }
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
            setIsActive(active)
        }, 10);
    }, [active])

    const _closeModal = () => {
        setIsActive(false)
        document.body.style.overflow = 'unset';
        setTimeout(() => {
            closeModal()
        }, 300);
    }

    useImperativeHandle(ref, () => {
		return {
			closeModalFromController:
			  ()=>{_closeModal()}
		  };
	
	});

    
    const [ opts, setOpts ] = useState([
        {label:"Nearest", value:'calcDistance', sortOrder:"asc"},
        // {label:"Cheapest", value:'price', sortOrder:"asc"},
        {label:"Featured", value:'score', sortOrder:"desc", selected: true}
    ])

    useEffect(() => {
        const close = (e) => {
          if(e.keyCode === 27){
            _closeModal()
          }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    },[])

    return(
        <div style={{overflowX:'hidden'}} onMouseDown={(e)=>shouldCloseModal(e)} className={`modal-overlay d-flex ${isActive}`}>
            {
                menu && <div className='close-modal' style={{zIndex:'2'}}>
                    <i className='bx bx-x cursor-pointer' onClick={_closeModal}></i>
                </div>
            }
            <div className={`modal-wrapper ${menu ? 'top-close' : ''} ${fullWidth ? 'full-width' : ''}`}>
                <div style={style} className={`${fullWidth ? 'full-width' : ''} modal-content ${wide ? 'wide' : extrawide ? 'extrawide' : ''}`}>
                    <div style={{zIndex:'2'}}>
                       
                        {
                            menu ? (
                                menu()
                            ) : (
                                !hideCloseButton && <div className='close-modal-black' style={{zIndex:'2'}}>
                                    <i className='bx bx-x cursor-pointer' onClick={_closeModal}></i>
                                </div>
                                
                            )
                        }
                        
                    </div>
                    {
                        children
                    }
                </div>
            </div>
        </div>
    )
})
export default ModalController